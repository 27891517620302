import React from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "../Global";

const HomeCategories = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="categories">
      {data.categories.map((category, index) => (
        <img
          key={index}
          src={Global.PHOTO_URL + category.categoryImage}
          alt="category"
          onClick={() =>
            navigate(
              "/shop/category/" +
                category.categoryName.replaceAll(" ", "-") +
                "/" +
                category.categoryId +
                "?sort=new-arrival&page=1"
            )
          }
        />
      ))}
    </div>
  );
};

export default HomeCategories;
