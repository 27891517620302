import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/scss/style.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import PersistProvider from "./store/providers/persist-provider";
import { store } from "./store/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistProvider>
      <Router>
        <App />
      </Router>
    </PersistProvider>
  </Provider>
);
