import React from "react";
import HomeProduct from "./HomeProduct";
// import products from "../data/carousel";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

const HomeCarousel = ({ data }) => {
  return (
    <section className="product-section">
      <h1>{data.widgetTitle}</h1>
      <p>{data.widgetSubtitle}</p>

      <Swiper
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1650: {
            slidesPerView: 5,
          },
        }}
      >
        {data.products.map((product, index) => (
          <SwiperSlide key={index}>
            <HomeProduct product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default HomeCarousel;
