import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    productName: "",
    productDescription: "",
    price: 0,
    salePrice: 0,
    image: "",
    quantity: 0,
    soldQuantity: 0,
    categoryId: 0,
    isPublished: true,
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);

  const UploadImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setProduct((prevState) => ({
        ...prevState,
        image: reader.result,
      }));
    };
  };

  const AddProduct = async () => {
    try {
      var formdata = new FormData();
      formdata.append("ProductName", product.productName);
      formdata.append("ProductDescription", product.productDescription);
      formdata.append("Price", product.price);
      formdata.append("SalePrice", product.salePrice);
      formdata.append("Quantity", product.quantity);
      formdata.append("CategoryId", product.categoryId);
      formdata.append("IsPublished", product.isPublished);
      formdata.append("Image", file);

      const response = await fetch(Global.API_URL + "Product/AddProduct", {
        method: "POST",
        body: formdata,
        redirect: "follow",
      });
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        navigate("/admin/products");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditProduct = async () => {
    try {
      var formdata = new FormData();
      formdata.append("ProductName", product.productName);
      formdata.append("ProductDescription", product.productDescription);
      formdata.append("Price", product.price);
      formdata.append("SalePrice", product.salePrice);
      formdata.append("Quantity", product.quantity);
      formdata.append("CategoryId", product.categoryId);
      formdata.append("IsPublished", product.isPublished);
      formdata.append("Image", file);
      formdata.append("ImageUrl", product.image);

      const response = await fetch(
        Global.API_URL + "Product/EditProduct/" + id,
        {
          method: "PUT",
          body: formdata,
          redirect: "follow",
        }
      );
      const result = await response.json();
      
      if (result.code === 0) {
        toast.success(result.message);
        navigate("/admin/products");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const GetCategories = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Category/GetCategories?skip=0&take=100",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCategories(result.message);
            setLoading(false);
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetCategories();
  }, []);

  useEffect(() => {
    const GetProduct = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Product/GetProduct/" + id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setProduct(result.message);
              setLoading(false);
            } else {
              toast.error(result.message);
            }
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetProduct();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/products">Back</NavLink>
          <button onClick={parseInt(id) === 0 ? AddProduct : EditProduct}>
            Submit
          </button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group card-check">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={product.isPublished}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          isPublished: e.target.checked,
                        }))
                      }
                    />
                    <label>Publish</label>
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Product Name{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Product name"
                      value={product.productName}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          productName: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Product Description{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Product Description"
                      value={product.productDescription}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          productDescription: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Price{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="number"
                      placeholder="Price"
                      min="0"
                      value={product.price}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          price: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Sale Price{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="number"
                      placeholder="Sale price"
                      min="0"
                      value={product.salePrice}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          salePrice: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Quantity{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="number"
                      placeholder="Quantity"
                      min="0"
                      value={product.quantity}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          quantity: parseInt(e.target.value),
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Category{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <select
                      value={product.categoryId}
                      onChange={(e) =>
                        setProduct((prevState) => ({
                          ...prevState,
                          categoryId: parseInt(e.target.value),
                        }))
                      }
                    >
                      <option value={0}>-- Select --</option>
                      {categories.map((category, index) => (
                        <option key={index} value={category.categoryId}>
                          {category.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Image{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <br />
                    {(file || product.image !== "") && (
                      <img
                        height= "400"
                        src={file ? product.image : Global.PHOTO_URL + product.image}
                        alt=""
                      />
                    )} 
                    <br />
                    <br />
                    <input type="file" onChange={UploadImage} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageProduct;
