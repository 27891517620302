import React, { useEffect } from "react";
import HomeBanner from "../wrappers/HomeBanner";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../store/slices/cart-slice";
import { useDispatch } from "react-redux";

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <Layout>
      <HomeBanner mt="mb-155" />

      <div className="shop-info">
        <p className="shop-items">Home / Order</p>
      </div>

      <div className="order">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
          <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
        <p>Your order has been successfully placed!</p>
        <button onClick={() => navigate("/")}>Back To Home</button>
      </div>
    </Layout>
  );
};

export default Order;
