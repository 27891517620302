import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";
import Layout from "../../layout/Layout";

const ManageOrder = () => {
  const { id } = useParams();

  const [order, setOrder] = useState({
    orderId: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    cityName: "",
    street: "",
    building: "",
    floor: "",
    notes: "",
    shippingCost: 0,
    total: 0,
    discountedTotal: 0,
    orderStatusId: 0,
    orderStatusName: "",
    details: [],
  });
  const [loading, setLoading] = useState(true);

  const MarkAsProcessing = async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Order/MarkOrderAsProcessing/" + id,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          toast.success(result.message);
          setOrder((prevState) => ({
            ...prevState,
            orderStatusId: 2,
            orderStatusName: "Processing",
          }));
        } else {
          toast.error(result.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  const MarkAsOnDelivery = async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Order/MarkOrderAsOnDelivery/" + id,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          toast.success(result.message);
          setOrder((prevState) => ({
            ...prevState,
            orderStatusId: 3,
            orderStatusName: "On Delivery",
          }));
        } else {
          toast.error(result.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  const MarkAsCompleted = async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Order/MarkOrderAsCompleted/" + id,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          toast.success(result.message);
          setOrder((prevState) => ({
            ...prevState,
            orderStatusId: 4,
            orderStatusName: "Completed",
          }));
        } else {
          toast.error(result.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetOrder = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Order/GetOrder/" + id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setOrder(result.message);
              setLoading(false);
            } else {
              toast.error(result.message);
            }
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetOrder();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/orders">Back</NavLink>
          {order.orderStatusId === 1 && (
            <button onClick={MarkAsProcessing}>Mark as processing</button>
          )}

          {order.orderStatusId === 2 && (
            <button onClick={MarkAsOnDelivery}>Mark as on delivery</button>
          )}

          {order.orderStatusId === 3 && (
            <button onClick={MarkAsCompleted}>Mark as Completed</button>
          )}
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group">
                    <h5>Customer Details</h5>
                    <label>
                      Name: {order.firstName + " " + order.lastName}
                    </label>
                    <label>Email Address: {order.emailAddress}</label>
                    <label>Phone Number: {order.phoneNumber}</label>
                  </div>

                  <div className="card-group">
                    <h5>Delivery Details</h5>
                    <label>City: {order.cityName}</label>
                    <label>Streer: {order.street}</label>
                    <label>Building: {order.building}</label>
                    <label>Floor: {order.floor}</label>
                    {order.notes !== "" && <label>Notes: {order.notes}</label>}
                  </div>
                </div>

                <div className="line"></div>
                <div className="card-row">
                  <div className="card-group">
                    <label>Status: {order.orderStatusName}</label>
                  </div>
                  <div className="card-group">
                    <label>Delivery Cost: {order.shippingCost}$</label>
                  </div>
                  <div className="card-group">
                    <label>Total: {order.total !== order.discountedTotal && <strike>{order.total}$</strike>} {order.discountedTotal}$</label>
                  </div>
                  <div className="card-group">
                    <label>Final Total: {order.discountedTotal + order.shippingCost}$</label>
                  </div>
                </div>
                <div className="line"></div>

                {order.details.map((detail, index) => (
                  <div className="card-row" key={index}>
                    <div className="card-group">
                      <img src={Global.PHOTO_URL + detail.productImage} alt="" />
                    </div>
                    <div className="card-group">
                      <label>Product Name: {detail.productName}</label>
                      <label>Price: ${detail.price}</label>
                      {detail.salePrice !== 0 && <label>Sale Price: ${detail.salePrice}</label>}
                      <label>Quantity: {detail.quantity}</label>
                      <label>Subtotal: ${detail.subTotal}</label>
                      <label>
                        Final Subtotal: ${detail.discountedSubTotal}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageOrder;
