import React, { useEffect, useState, Fragment } from "react";
import Layout from "../layout/Layout";
import HomeCarousel from "../wrappers/HomeCarousel";
import { useParams, useNavigate } from "react-router-dom";
// import products from "../data/products";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFromWishlist,
  addToWishlist,
} from "../store/slices/wishlist-slice";
import { addToCart, openCart } from "../store/slices/cart-slice";
import { toast } from "react-toastify";
import { Global } from "../Global";

const Product = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [zoomed, setZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [product, setProduct] = useState({
    id: 0,
    name: "",
    description: "",
    image: "",
    price: 0,
    salePrice: 0,
  });
  const [relatedProducts, setRelatedProducts] = useState({
    firstProducts: [],
    secondProducts: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const { wishlistItems } = useSelector((state) => state.wishlist);
  const isInWishlist = wishlistItems.some(
    (p) => p.productId === product.productId
  );

  const AddToWishList = () => {
    dispatch(addToWishlist(product));
  };

  const RemoveFromWishList = () => {
    dispatch(deleteFromWishlist(product.productId));
  };

  const AddToCart = () => {
    dispatch(addToCart(product));
    dispatch(openCart());
  };

  const BuyNow = () => {
    dispatch(addToCart(product));
    navigate("/checkout");
  };

  const handleMouseEnter = () => {
    setZoomed(true);
  };

  const handleMouseLeave = () => {
    setZoomed(false);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.pageX - left) / width;
    const y = (e.pageY - top) / height;

    setPosition({ x, y });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const GetProduct = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Client/GetProduct/" + id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setProduct(result.message);
            setIsLoading(false);
          } else {
            toast.error(result.message);
            navigate("/");
          }
        } else {
          toast.error(result.message);
          navigate("/");
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    const GetRelatedProducts = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Client/GetRelatedProducts/" + id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setRelatedProducts(result.message);
          }
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetProduct();
    GetRelatedProducts();
  }, [navigate, id]);

  return (
    <Layout>
      {isLoading ? (
        <div className="home-loader">
          <div></div>
          <div></div>
        </div>
      ) : (
        <Fragment>
          <div className="shop-info mb-195">
            <p className="shop-items">Home / Product</p>
          </div>

          <div className="single-product">
            <div
              className={zoomed ? "magnifier zoomed" : "magnifier"}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <div
                className="magnifier-image"
                style={{
                  backgroundImage: `url(${Global.PHOTO_URL + product.image})`,
                  backgroundPosition: `${position.x * 100}% ${
                    position.y * 100
                  }%`,
                  backgroundSize: zoomed ? "300% auto" : "100%",
                }}
              />
            </div>

            <div className="single-product-info">
              <p className="product-name">{product.productName}</p>
              <p className="product-description">
                {product.productDescription}
              </p>

              {product.salePrice !== 0 ? (
                <div className="product-pricing">
                  <strike>
                    <p className="product-price">${product.price}</p>
                  </strike>
                  <p className="product-sale-price">${product.salePrice}</p>
                </div>
              ) : (
                <p className="product-price">${product.price}</p>
              )}

              <div className="product-actions">
                <button className="btn-cart" onClick={AddToCart}>
                  Add To Cart
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24.636 21.679"
                  fill={isInWishlist ? "#000" : "#fff"}
                  stroke="#000"
                  onClick={isInWishlist ? RemoveFromWishList : AddToWishList}
                >
                  <path
                    id="Icon_feather-heart"
                    data-name="Icon feather-heart"
                    d="M23.675,6.281a6.087,6.087,0,0,0-8.611,0L13.891,7.454,12.718,6.281a6.089,6.089,0,1,0-8.611,8.611L5.28,16.065l8.611,8.611L22.5,16.065l1.173-1.173a6.087,6.087,0,0,0,0-8.611Z"
                    transform="translate(-1.573 -3.747)"
                  ></path>
                </svg>
              </div>

              <button className="btn-buy" onClick={BuyNow}>
                Buy Now
              </button>
            </div>
          </div>

          {relatedProducts.firstProducts.length > 0 && (
            <HomeCarousel
              data={{
                widgetTitle: "You may also like",
                widgetSubtitle: "Check our products",
                products: relatedProducts.firstProducts,
              }}
            />
          )}

          {relatedProducts.secondProducts.length > 0 && (
            <HomeCarousel
              data={{
                widgetTitle: "You may also like",
                widgetSubtitle: "Check our products",
                products: relatedProducts.secondProducts,
              }}
            />
          )}
        </Fragment>
      )}
    </Layout>
  );
};

export default Product;
