import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import HomeBanner from "../wrappers/HomeBanner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import { closeCart } from "../store/slices/cart-slice";
import { toast } from "react-toastify";
import { Global } from "../Global";

const Checkout = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cityId, setCityId] = useState(0);
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [note, setNote] = useState("");

  const [cities, setCities] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);

  let subTotal = cartItems.reduce((acc, cur) => {
    if (cur.salePrice === 0) acc += cur.price * cur.quantity;
    else acc += cur.salePrice * cur.quantity;
    return acc;
  }, 0);

  const CreateOrder = async () => {
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      cityId === 0 ||
      isNaN(cityId) ||
      street.trim() === "" ||
      building.trim() === "" ||
      floor.trim() === "" ||
      phoneNumber.trim() === ""
    ) {
      cogoToast.error("Fill required fields");
    } else if (!email.includes("@") || !email.includes(".")) {
      cogoToast.error("Invalid email");
    } else {
      try {
        let array = [];
        cartItems.forEach((item) => {
          array.push({
            productId: item.productId,
            quantity: item.quantity,
          });
        });

        const response = await fetch(Global.API_URL + "Order/CreateOrder", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            phoneNumber: phoneNumber,
            cityId: cityId,
            street: street,
            building: building,
            floor: floor,
            notes: note,
            details: cartItems,
          }),
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            navigate("/order");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    }
  };

  const ChangeCity = (value, cost) => {
    setCityId(parseInt(value));
    if (!isNaN(cost)) setShippingCost(cost);
    else setShippingCost(0);
  };

  useEffect(() => {
    const GetCities = async () => {
      try {
        const response = await fetch(Global.API_URL + "Client/GetCities", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCities(result.message);
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetCities();
  }, []);

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate("/cart");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      dispatch(closeCart());
    }
  }, [cartItems, navigate, dispatch]);

  return (
    <Layout>
      <HomeBanner mt="mb-155" />

      <div className="shop-info">
        <p className="shop-items">Home / Checkout</p>
      </div>

      <div className="checkout">
        <div className="shipping-details">
          <h2 className="shipping-title">Shipping Details</h2>
          <div className="shipping-row">
            <div className="shipping-group">
              <label>
                First Name{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="shipping-group">
              <label>
                Last Name{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="shipping-row">
            <div className="shipping-group">
              <label>
                Email Address{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="shipping-row">
            <div className="shipping-group">
              <label>
                Phone Number{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>

          <div className="shipping-row">
            <div className="shipping-group">
              <label>
                City{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <select
                value={cityId}
                onChange={(e) =>
                  ChangeCity(
                    parseInt(e.target.value),
                    parseInt(e.target.options[e.target.selectedIndex].title)
                  )
                }
              >
                <option value={0}>Select</option>
                {cities.map((city, index) => (
                  <option
                    key={index}
                    value={city.cityId}
                    title={city.shippingCost}
                  >
                    {city.cityName}
                  </option>
                ))}
              </select>
            </div>

            <div className="shipping-group">
              <label>
                Street{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
          </div>

          <div className="shipping-row">
            <div className="shipping-group">
              <label>
                Building{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Building"
                value={building}
                onChange={(e) => setBuilding(e.target.value)}
              />
            </div>

            <div className="shipping-group">
              <label>
                Floor{" "}
                <abbr className="required" title="required">
                  *
                </abbr>
              </label>
              <input
                type="text"
                placeholder="Floor"
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
              />
            </div>
          </div>

          <div className="shipping-row">
            <div className="shipping-group">
              <label>Order notes (optional)</label>
              <textarea
                rows="8"
                placeholder="Notes about your order, eg. special notes for delivery"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="order">
          <div className="summary">
            <h3 className="summary-title">Your Order</h3>
            <table className="table table-summary">
              <thead>
                <tr className="summary-product">
                  <th>Product</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr className="summary-details" key={index}>
                    <td>
                      {item.productName} x {item.quantity}
                    </td>
                    <td>
                      {item.salePrice === 0
                        ? item.price.toFixed(2)
                        : item.salePrice.toFixed(2)}
                      $
                    </td>
                  </tr>
                ))}
                <tr className="summary-subtotal">
                  <td>Subtotal:</td>
                  <td>{subTotal.toFixed(2)}$</td>
                </tr>
                <tr className="summary-shipping">
                  <td>Shipping:</td>
                  <td>
                    {cityId === 0 ? "to be calculated" : shippingCost + "$"}
                  </td>
                </tr>
                <tr className="summary-total">
                  <td>Total:</td>
                  <td>{(subTotal + shippingCost).toFixed(2)}$</td>
                </tr>
              </tbody>
            </table>
            <button type="submit" onClick={CreateOrder}>
              <span className="btn-text">Place Order</span>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
