import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "../../store/slices/admin-slice";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(logoutAdmin());
    navigate("/admin/login");
  };

  return (
    <Fragment>
      <header className="admin-header">
        <div className="admin-logo">
          <img src="/images/logo/logo.jpg" alt="logo" />
        </div>

        <div className="admin-top-header">
          <div className="admin-logout">
            <button onClick={Logout}>
              <svg
                id="icon-logout"
                xmlns="http://www.w3.org/2000/svg"
                className="text-danger me-1"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Logout
            </button>
          </div>
        </div>

        <div className="admin-sidebar-header">
          <div className="header-title">
            <div className="header-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-wrench"
                viewBox="0 0 16 16"
              >
                {" "}
                <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z" />{" "}
              </svg>
              <h6>Manage Website</h6>
            </div>
            <ul>
              <li onClick={() => navigate("/admin/menu")}>Menu</li>
              <li onClick={() => navigate("/admin/widgets")}>Widgets</li>
              <li onClick={() => navigate("/admin/slides")}>Slides</li>
              <li onClick={() => navigate("/admin/home-banner")}>Home Banner</li>
              <li onClick={() => navigate("/admin/banner")}>Banner</li>
              <li onClick={() => navigate("/admin/users")}>Users</li>
            </ul>
          </div>

          <div className="header-title">
            <div className="header-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-wrench"
                viewBox="0 0 16 16"
              >
                {" "}
                <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z" />{" "}
              </svg>
              <h6>Manage Products</h6>
            </div>
            <ul>
              <li onClick={() => navigate("/admin/categories")}>Categories</li>
              <li onClick={() => navigate("/admin/products")}>Products</li>
            </ul>
          </div>

          <div className="header-title">
            <div className="header-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-wrench"
                viewBox="0 0 16 16"
              >
                {" "}
                <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z" />{" "}
              </svg>
              <h6>Manage Orders</h6>
            </div>
            <ul>
              <li onClick={() => navigate("/admin/orders")}>Orders</li>
              <li onClick={() => navigate("/admin/cities")}>Cities</li>
            </ul>
          </div>
        </div>
      </header>
      {children}
    </Fragment>
  );
};

export default Layout;
