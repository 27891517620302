import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../Global";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const SendEmail = async (e) => {
    e.preventDefault(e);
    try {
      const response = await fetch(Global.API_URL + "User/ForgotPassword/" + email, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/admin/login");
        } else {
          toast.error(result.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={SendEmail}>
        <div className="login-card">
          <h1>Forgot Password?</h1>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button type="submit">Send Email</button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
