import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ListProduct = () => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const GetCities = async () => {
      try {
        const response = await fetch(Global.API_URL + "City/GetCities", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setCities(result.message);
            setLoading(false);
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetCities();
  }, []);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/city/0">+ New City</NavLink>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="table-responsive">
                  <div>
                    <table className="card-table shadow-hover dataTable">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>City Name</th>
                          <th>Shipping Cost</th>
                          <th>Publish Status</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cities.map((city, index) => (
                          <tr role="row" key={index}>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {city.cityId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {city.cityName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                ${city.shippingCost}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {city.isPublished ? "Yes" : "No"}
                              </span>
                            </td>
                            <td>
                              <div className="dropdown dropdown">
                                <div
                                  className="btn-link i-false dropdown-toggle"
                                  id="react-aria3069930207-3"
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      navigate("/admin/city/" + city.cityId)
                                    }
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListProduct;
