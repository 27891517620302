import React, { useState, useEffect, useRef } from "react";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ListOrder = () => {
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const pageLimit = 10;
  const activePag = useRef(0);
  const navigate = useNavigate();

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    GetOrders(activePag.current * pageLimit, tab);
  };

  const GetOrders = async (skip, status) => {
    setTab(status);

    let api = "";
    if(status=== 1) {
      api= "Order/GetPendingOrders"
    } else if(status=== 2) {
      api="Order/GetProcessingOrders"
    }else if(status=== 3) {
      api="Order/GetOnDeliveryOrders"
    }else if(status=== 4) {
      api="Order/GetCompletedOrders"
    }

    try {
      const response = await fetch(
        Global.API_URL + api + "?skip=" + skip + "&take=" + pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        if (result.code === 0) {
          setOrders(result.message);
          setTotalCount(result.count);
          setLoading(false);
        } else {
          toast.error(result.message);
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetPendingOrders = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Order/GetPendingOrders?skip=0&take=" + pageLimit,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            setOrders(result.message);
            setTotalCount(result.count);
            setLoading(false);
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetPendingOrders();
  }, []);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <ul>
                  <li
                    className={tab === 1 ? "active" : ""}
                    onClick={() => GetOrders(0, 1)}
                  >
                    Pending
                  </li>
                  <li
                    className={tab === 2 ? "active" : ""}
                    onClick={() => GetOrders(0, 2)}
                  >
                    Processing
                  </li>
                  <li
                    className={tab === 3 ? "active" : ""}
                    onClick={() => GetOrders(0, 3)}
                  >
                    On Delivery
                  </li>
                  <li
                    className={tab === 4 ? "active" : ""}
                    onClick={() => GetOrders(0, 4)}
                  >
                    Completed
                  </li>
                </ul>
                <div className="table-responsive">
                  <div>
                    <table className="card-table shadow-hover dataTable">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>Name</th>
                          <th>Email Address</th>
                          <th>Phone Number</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order, index) => (
                          <tr role="row" key={index}>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {order.orderId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {order.firstName + " " + order.lastName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {order.emailAddress}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {order.phoneNumber}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                ${order.total}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {order.status}
                              </span>
                            </td>
                            <td>
                              <div className="dropdown dropdown">
                                <div
                                  className="btn-link i-false dropdown-toggle"
                                  id="react-aria3069930207-3"
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      navigate(
                                        "/admin/order/" + order.orderId
                                      )
                                    }
                                  >
                                    <path
                                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                      stroke="#262626"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="content-pagination">
                      <div className="pagination-info">
                        Showing 1of 1 entries
                      </div>
                      <div className="pagination-button">
                        <button
                          className="previous"
                          onClick={() =>
                            activePag.current > 0 &&
                            ChangePage(activePag.current - 1)
                          }
                        >
                          Previous
                        </button>
                        <span>
                          {paggination.map((number, index) => (
                            <button
                              className={
                                activePag.current === index
                                  ? "current active"
                                  : "current"
                              }
                              key={index}
                              onClick={() => ChangePage(index)}
                            >
                              {number}
                            </button>
                          ))}
                        </span>
                        <button
                          className="next"
                          onClick={() =>
                            activePag.current + 1 < paggination.length &&
                            ChangePage(activePag.current + 1)
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ListOrder;
