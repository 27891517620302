import React from "react";
import HomeProduct from "./HomeProduct";

const HomeGrid = ({ products }) => {
  return (
    <section className="product-section">
      <div className="product-grid">
        {products.map((product, index) => (
          <HomeProduct key={index} product={product} />
        ))}
      </div>
    </section>
  );
};

export default HomeGrid;
