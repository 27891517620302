import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import HomeBanner from "../wrappers/HomeBanner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteFromCart,
  addToCart,
  decreaseQuantity,
  closeCart,
} from "../store/slices/cart-slice";
import { Global } from "../Global";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  let quantityTotal = cartItems.reduce((acc, cur) => {
    return acc + parseInt(cur.quantity, 10);
  }, 0);
  let cartTotalPrice = 0;
  let total = cartItems.reduce((acc, cur) => {
    return acc + cur.salePrice === 0
      ? (cartTotalPrice += cur.price * cur.quantity)
      : (cartTotalPrice += cur.salePrice * cur.quantity);
  }, 0);

  const RemoveFromCart = (id) => {
    dispatch(deleteFromCart(id));
  };

  const DecreaseQuantity = (product) => {
    dispatch(decreaseQuantity(product));
  };

  const AddToCart = (product) => {
    dispatch(addToCart(product));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(closeCart());
  }, [dispatch]);

  return (
    <Layout>
      <HomeBanner mt="mb-155" />

      <div className="shop-info">
        <p className="shop-items">Home / Cart - {quantityTotal} items</p>
      </div>

      {cartItems.length === 0 ? (
        <div className="empty-wishlist">
          <p>Oops! No items found in cart.</p>
          <button onClick={() => navigate("/")}>Add Items</button>
        </div>
      ) : (
        <div className="cart">
          <div className="cart-scroll">
            <table className="cart-items">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Subtotal</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="cart-items">
                {cartItems.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img
                          src={Global.PHOTO_URL + item.image}
                          alt=""
                          onClick={() =>
                            navigate("/shop-product/" + item.productId)
                          }
                        />
                      </td>
                      <td>{item.productName}</td>
                      <td>
                        {item.salePrice === 0 ? item.price : item.salePrice}$
                      </td>
                      <td className="product-quantity">
                        <div className="cart-plus-minus">
                          <button
                            className="dec qtybutton"
                            onClick={() => DecreaseQuantity(item)}
                          >
                            -
                          </button>
                          <input
                            className="cart-plus-minus-box"
                            type="text"
                            value={item.quantity}
                            readOnly
                          />
                          <button
                            className="inc qtybutton"
                            onClick={() => AddToCart(item)}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td>
                        {item.salePrice === 0
                          ? item.price * item.quantity
                          : item.salePrice * item.quantity}
                        $
                      </td>
                      <td className="product-remove">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          id="Layer_1"
                          width="14"
                          height="14"
                          viewBox="0 0 50 50"
                          enableBackground="new 0 0 50 50"
                          onClick={() => RemoveFromCart(item.productId)}
                        >
                          <path
                            fill="#231F20"
                            d="M9.016,40.837c0.195,0.195,0.451,0.292,0.707,0.292c0.256,0,0.512-0.098,0.708-0.293l14.292-14.309  l14.292,14.309c0.195,0.196,0.451,0.293,0.708,0.293c0.256,0,0.512-0.098,0.707-0.292c0.391-0.39,0.391-1.023,0.001-1.414  L26.153,25.129L40.43,10.836c0.39-0.391,0.39-1.024-0.001-1.414c-0.392-0.391-1.024-0.391-1.414,0.001L24.722,23.732L10.43,9.423  c-0.391-0.391-1.024-0.391-1.414-0.001c-0.391,0.39-0.391,1.023-0.001,1.414l14.276,14.293L9.015,39.423  C8.625,39.813,8.625,40.447,9.016,40.837z"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="cart-box">
            <div className="cart-summary">
              <h3 className="summary-title">Cart Total</h3>
              <table className="table table-summary">
                <tbody>
                  <tr className="summary-subtotal">
                    <td>Subtotal:</td>
                    <td>{total.toFixed(2)}$</td>
                  </tr>
                  <tr className="summary-shipping">
                    <td>Shipping:</td>
                    <td>to be caculated</td>
                  </tr>
                  <tr className="summary-total">
                    <td>Total:</td>
                    <td>{total.toFixed(2)}$</td>
                  </tr>
                </tbody>
              </table>
              <button onClick={() => navigate("/checkout")}>
                Proceed To Checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Cart;
