const { createSlice } = require("@reduxjs/toolkit");

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistItems: [],
  },
  reducers: {
    addToWishlist(state, action) {
      const isInWishlist = state.wishlistItems.findIndex(
        (item) => item.productId === action.payload.productId
      );
      if (isInWishlist === -1) {
        state.wishlistItems.push(action.payload);
      }
    },
    deleteFromWishlist(state, action) {
      state.wishlistItems = state.wishlistItems.filter(
        (item) => item.productId !== action.payload
      );
    },
  },
});

export const {
  addToWishlist,
  addAllToWishlist,
  deleteFromWishlist,
  deleteAllFromWishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
