import React from "react";
import { Global } from "../Global";
import { useSelector } from "react-redux";

const HomeBanner = ({ mt, image }) => {
  const { banner } = useSelector((state) => state.data);

  return (
    <img src={Global.PHOTO_URL + banner.bannerImage} alt="banner" className={"banner " + mt} />
  );
};

export default HomeBanner;
