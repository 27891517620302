const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    isOpenCart: false,
    cartItems: [],
  },
  reducers: {
    addToCart(state, action) {
      const product = action.payload;

      if (product && product.quantity > 0) {
        const cartItem = state.cartItems.find(
          (item) => item.productId === product.productId
        );

        if (!cartItem) {
          state.cartItems.push({
            ...product,
            quantity: 1,
          });
        } else {
          // if (cartItem.quantity + 1 <= product.quantity) {
            state.cartItems = state.cartItems.map((item) =>
              item.productId === product.productId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
          // }
        }
      }
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.productId !== action.payload
      );
    },
    decreaseQuantity(state, action) {
      const product = action.payload;
      if (product.quantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) => item.productId !== product.productId
        );
      } else {
        state.cartItems = state.cartItems.map((item) =>
          item.productId === product.productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }
    },
    deleteAllFromCart(state) {
      state.cartItems = [];
    },
    openCart(state) {
      state.isOpenCart = true;
    },
    closeCart(state) {
      state.isOpenCart = false;
    },
    clearCart(state) {
      state.isOpenCart = false;
      state.cartItems = [];
    }
  },
});

export const {
  addToCart,
  deleteFromCart,
  decreaseQuantity,
  deleteAllFromCart,
  openCart,
  closeCart,
  clearCart
} = cartSlice.actions;
export default cartSlice.reducer;
