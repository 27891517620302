import React from "react";
import { Global } from "../Global";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

const HeroSlider = ({ mt, data }) => {
  return (
    <Swiper
      navigation={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Navigation]}
      className={"hero-slider " + mt}
    >
      {data.map((slide, index) => (
        <SwiperSlide key={index}>
          <img src={Global.PHOTO_URL + slide} alt="slider" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroSlider;
