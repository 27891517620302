import React from "react";
import HomeProduct from "./HomeProduct";
// import { useNavigate } from "react-router-dom";

const HomeGrid = ({ data }) => {
  // const navigate = useNavigate();

  return (
    <section className="product-section">
      <h1>{data.widgetTitle}</h1>
      <p>{data.widgetSubtitle}</p>

      <div className="product-grid">
        {data.products.map((product, index) => (
          <HomeProduct key={index} product={product} />
        ))}
      </div>

      {/* <div className="widget-action">
        <button className="btn-shop" onClick={() => navigate("/shop?sort=new-arrival&page=1")}>
          View All
        </button>
      </div> */}
    </section>
  );
};

export default HomeGrid;
