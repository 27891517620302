const { createSlice } = require("@reduxjs/toolkit");

const dataSlice = createSlice({
    name: "data",
    initialState: {
      banner: {
        bannerId: "",
        bannerImage: "",
      },
      menu: [],
    },
    reducers: {
      setBanner(state, action) {
        state.banner = action.payload;
      },
      setMenu(state, action) {
        state.menu = action.payload;
      },
    },
  });

  export const {
    setBanner,
    setMenu
  } = dataSlice.actions;
  export default dataSlice.reducer;