import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../Global";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const guid = queryParams.get("guid");
  const email = queryParams.get("email");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const ResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords did not match");
    } else {
      try {
        const response = await fetch(Global.API_URL + "User/ResetPassword", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(password),
        });

        const result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            navigate("/admin/login");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    }
  };

  useEffect(() => {
    const ConfirmEmail = async () => {
      if (email && guid) {
        try {
          const response = await fetch(
            Global.API_URL + "User/ConfirmEmail/" + email + "/" + guid,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          if (response.ok) {
            if (result.code !== 0) {
              toast.error(result.message);
              navigate("/admin/login");
            }
          } else {
            toast.error(result.message);
            navigate("/admin/login");
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        navigate("/admin/login");
      }
    };

    ConfirmEmail();
  }, [email, guid, navigate]);

  return (
    <div className="login-page">
      <form onSubmit={ResetPassword}>
        <div className="login-card">
          <h1>Reset Password</h1>

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
