import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFromWishlist,
  addToWishlist,
} from "../store/slices/wishlist-slice";
import { addToCart, openCart } from "../store/slices/cart-slice";
import { useNavigate } from "react-router-dom";
import { Global } from "../Global";

const HomeProduct = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wishlistItems } = useSelector((state) => state.wishlist);
  const isInWishlist = wishlistItems.some(
    (p) => p.productId === product.productId
  );

  const AddToWishList = () => {
    dispatch(addToWishlist(product));
  };

  const RemoveFromWishList = () => {
    dispatch(deleteFromWishlist(product.productId));
  };

  const AddToCart = () => {
    dispatch(addToCart(product));
    dispatch(openCart());
  };

  return (
    <div className="product">
      <div className="image-box">
        <img
          src={Global.PHOTO_URL + product.image}
          alt="product"
          onClick={() => navigate("/shop-product/" + product.productId)}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24.636 21.679"
          fill={isInWishlist ? "#000" : "#fff"}
          stroke="#000"
          onClick={isInWishlist ? RemoveFromWishList : AddToWishList}
        >
          <path
            id="Icon_feather-heart"
            data-name="Icon feather-heart"
            d="M23.675,6.281a6.087,6.087,0,0,0-8.611,0L13.891,7.454,12.718,6.281a6.089,6.089,0,1,0-8.611,8.611L5.28,16.065l8.611,8.611L22.5,16.065l1.173-1.173a6.087,6.087,0,0,0,0-8.611Z"
            transform="translate(-1.573 -3.747)"
          ></path>
        </svg>
      </div>

      <p className="product-name">{product.productName}</p>
      <p className="product-description">{product.productDescription}</p>

      {product.salePrice !== 0 ? (
        <div className="product-pricing">
          <strike>
            <p className="product-price">${product.price}</p>
          </strike>
          <p className="product-sale-price">${product.salePrice}</p>
        </div>
      ) : (
        <p className="product-price">${product.price}</p>
      )}

      <button className="btn-cart" onClick={AddToCart}>
        Add To Cart
      </button>
    </div>
  );
};

export default HomeProduct;
