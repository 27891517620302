import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";

const ManageMenu = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    isConfirmed: true,
    isLocked: false,
  });
  const [loading, setLoading] = useState(true);

  const AddUser = async () => {
    const response = await fetch(Global.API_URL + "User/AddUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        isLocked: user.isLocked,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/users");
    } else {
      toast.error(result.message);
    }
  };

  const EditUser = async () => {
    const response = await fetch(Global.API_URL + "User/EditUser/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        isLocked: user.isLocked,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      toast.success(result.message);
      navigate("/admin/users");
    } else {
      toast.error(result.message);
    }
  };

  useEffect(() => {
    const GetUser = async () => {
      if (parseInt(id) !== 0) {
        try {
          const response = await fetch(Global.API_URL + "User/GetUser/" + id, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          const result = await response.json();
          if (response.ok) {
            if (result.code === 0) {
              setUser(result.message);
              setLoading(false);
            } else {
              toast.error(result.message);
            }
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        setLoading(false);
      }
    };

    GetUser();
  }, [id]);

  return (
    <Layout>
      <div className="admin-content">
        <div className="content-page-header">
          <NavLink to="/admin/users">Back</NavLink>
          <button onClick={parseInt(id) === 0 ? AddUser : EditUser}>
            Submit
          </button>
        </div>

        <div className="content-page">
          <div className="content-card">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="content-card-body">
                <div className="card-row">
                  <div className="card-group card-check">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={user.isLocked}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          isLocked: e.target.checked,
                        }))
                      }
                    />
                    <label>Lock</label>
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      First Name{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="First name"
                      value={user.firstName}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Last Name
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={user.lastName}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="card-row">
                  <div className="card-group">
                    <label>
                      Username{" "}
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Email"
                      value={user.email}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="card-group">
                    <label>
                      Phone Number
                      <abbr className="required" title="required">
                        *
                      </abbr>
                    </label>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      value={user.phoneNumber}
                      onChange={(e) =>
                        setUser((prevState) => ({
                          ...prevState,
                          phoneNumber: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageMenu;
