import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import ShopGrid from "../wrappers/ShopGrid";
import HomeBanner from "../wrappers/HomeBanner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const { wishlistItems } = useSelector((state) => state.wishlist);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <HomeBanner
        mt="mb-155"
      />

      <div className="shop-info">
        <p className="shop-items">Home / Wishlist - {wishlistItems.length} items</p>
      </div>

      {wishlistItems.length === 0 ? (
        <div className="empty-wishlist">
          <p>Oops! No items found in wishlist.</p>
          <button onClick={() => navigate("/")}>Add Items</button>
        </div>
      ) : (
        <ShopGrid products={wishlistItems} />
      )}
    </Layout>
  );
};

export default Wishlist;
