const { createSlice } = require("@reduxjs/toolkit");

const adminSlice = createSlice({
    name: "admin",
    initialState: {
      isLogged: false,
      adminId: "",
    },
    reducers: {
      loginAdmin(state, action) {
        state.isLogged = true;
        state.adminId = action.payload;
      },
      logoutAdmin(state, action) {
        state.isLogged = false;
        state.adminId = "";
      },
    },
  });

  export const {
    loginAdmin,
    logoutAdmin
  } = adminSlice.actions;
  export default adminSlice.reducer;